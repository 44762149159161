(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name rcrFrontend.service:Downloads
   *
   * @description
   *
   */
  angular
    .module('rcrFrontend')
    .service('Downloads', Downloads);

  function Downloads($filter) {
    var self = this;

    self.get = function () {
      return 'Downloads';
		
    };
	self.csvColumnOrder = ['id','submitter','location','status','dateSubmitted','dateApproved','department','headOfDepartment','description','reason','isExisting','currentWording','revisedWording','wordingLocation','finalWording','asap', 'whenToChange', 'howAffect','knockOnAffect','productsAffected', 'regulatoryVersion','systemChanges', 'trainingRequired', 'impactOnOperations', 'impactIfChangeNotMade','additionalInformation'];
	self.csvHeader = ['ID','Submitter','Location', 'Status','Date Submitted','Appr/Rej Date','Department', 'Head of Department', 'Change Description', 'Change Reason', 'Is Existing', 'Current Wording', 'Suggested Wording', 'Wording Location', 'Approved Wording', 'Change from ASAP', 'Change from Specific Date', 'How It Affects Customers', 'Knock on Effect','Products Affected', 'Regulatory Version', 'System Changes', 'Training Required', 'Impact on Operations', 'Impact if Change not made', 'Additional Information' ];

	self.cphCsvColumnOrder = self.csvColumnOrder.slice(0); // copy original array
    self.cphCsvColumnOrder.splice(8, 0, 'cphfolio'); // splice new value into it
	self.cphCsvHeader = self.csvHeader.slice(0); // copy original array
	self.cphCsvHeader.splice(8, 0, 'Portfolio'); // splice new value into it

	self.rcrBuild = function(rcrArray){
		//This function builds an array of **REQUEST** objects suitable for csv download for use with ng-csv. It **DOES NOT** actually do the download.
		//If you want to download data other than requests, you'll need to write a new function to build a suitable array.
		var downloadArray = []; 																					//create empty array, this will be the array of objects that is converted to csv
		
		_.forEach(rcrArray, function(parent, key){																	//loop through the array of rcrParent objects passed to the function
			var tidyparent = {}; 																					//create empty object, this will be one row in our csv
			
			_.forEach(parent, function(prop, propkey){																//loop through properties of the rcrParent object
				//check which rcrParent property we're currently on.
				if (propkey == "Rcr"){
					
					_.forEach(prop[0], function(rcrprop, rcrpropkey){												//if current rcrParent property is Rcr, loop through the properties of the first Rcr object in the array
						//check which Rcr property we're currently on.
						if (rcrpropkey == "regulatoryVersion"){
							var obj = angular.fromJson(rcrprop);													//if current Rcr property is regulatory version make an object from json string.
							var tidyreg = "";																		//create empty string, this will be a list of selected regulatory version
							
							_.forEach(obj, function(isSelected, regv){												//loop through the properties of the reg versions object
								if (isSelected){																	//if the reg version is set to true
									tidyreg = tidyreg + regv + ", ";												//append it to the empty string;
								}
							});
							
							tidyparent["regulatoryVersion"]=tidyreg; 												//set the regulatoryVersion property of the row object to the reg version string
						}else if(rcrpropkey == "when"){																//if current Rcr property is when change should come into effect
							tidyparent["whenToChange"]= $filter('date')(rcrprop * 1000, "dd MMM yy");			//convert unix epoch date to formatted date and set it as whenToChange property of the row object
						}else if(rcrpropkey == "isExisting" || rcrpropkey == "asap"){
							if (rcrprop == "TRUE" || rcrprop == true || rcrprop == "true"){
								tidyparent[rcrpropkey]="Yes";
								if(rcrpropkey == "asap"){
									tidyparent["whenToChange"]="-";
								}
							}else if (rcrprop == "FALSE" || rcrprop == false || rcrprop == "false"){
								tidyparent[rcrpropkey]="No";
							}
							
						}else if (rcrpropkey != "id" && rcrpropkey != "parentId" && rcrpropkey != "dateCreated" && rcrpropkey != "whenFmt" && rcrpropkey != "regVersion"){	//else if it's not one of the ignored properties
							tidyparent[rcrpropkey]=rcrprop;															//set the current Rcr property as the equivalent property in the row object.
						}
					});																								//end Rcr Loop
					
				}else if(propkey == "Locations"){
					tidyparent["location"] = prop["locationCode"];													//if current rcrParent property is Locations: set the location property of the row object to the locationCode property of the Locations object
				}else if(propkey == "Status"){
					tidyparent["status"] = prop["status"];															//if current rcrParent property is Status: set the status property of the row object to the status property of the Status object
				}else if(propkey == "id"){
					tidyparent["id"] = parent["idsix"];																//if current rcrParent property is id: set the id property of the row object to the idsix property of the rcrParent
				}else if(propkey == "dateSubmitted"){
					tidyparent["dateSubmitted"] = parent["dateSubmittedFmt"];										//if current rcrParent property is dateSubmitted: set the dateSubmitted property of the row object to the dateSubmittedFmt property of the rcrParent
				}else if(propkey == "dateApproved"){
					tidyparent["dateApproved"] = parent["dateApprovedFmt"];										//if current rcrParent property is dateApproved: set the dateApproved property of the row object to the formatted version of property of the dateApproved
				}
			});																										//end rcrParent Loop
			
			downloadArray.push(tidyparent);																			//push the populated, flat row object to the download array
		});
		
		return downloadArray;																						//return the populated download array to the ng-csv directive.
	}
  }
}());
